import { withErrorBoundary } from 'components/ErrorBoundary';
import Link from 'components/Globals/Base/Link';
import getCommonTestId from 'constants/testsIds/common';
import { BreadcrumbsProps } from 'interfaces/navigation/Breadcrumbs';
import { UIFragments } from 'utils/log/constants/uiFragments';
import { Crumb, CrumbWrapper, List, Wrapper } from './BreadcrumbsStyles';

const Breadcrumbs = ({ items, hasBrandColor }: BreadcrumbsProps) => {
  const trackingData = {
    contentPlacementCD: 'top breadcrumb navigation',
    eventAction: 'click breadcrumb link',
    eventCategory: 'navigation',
  };

  const mobileItem = items.length > 1 ? items[items.length - 2] : items[0];

  return (
    <Wrapper
      aria-label='Breadcrumb'
      className='breadcrumbs'
      data-cy={getCommonTestId('BREAD_CRUMBS')}
    >
      <List
        className='tablet-and-mobile'
        data-cy={getCommonTestId('TABLET_AND_MOBILE_BREADCRUMBS')}
      >
        <CrumbWrapper>
          <Link
            type={hasBrandColor ? 'brandAccentPrimary' : 'bluePrimary'}
            href={mobileItem?.link}
            trackingData={{ ...trackingData, eventLabel: `${mobileItem.label}` }}
          >
            <Crumb>{mobileItem?.label}</Crumb>
          </Link>
        </CrumbWrapper>
      </List>
      <List data-cy={getCommonTestId('DESKTOP_BREADCRUMBS')}>
        {items.map((item, index) => (
          <CrumbWrapper key={item.link}>
            {index === items.length - 1 && (
              <Crumb
                aria-current='page'
                $last
              >
                {item.label}
              </Crumb>
            )}
            {index !== items.length - 1 && (
              <Link
                type={hasBrandColor ? 'brandAccentPrimary' : 'bluePrimary'}
                href={item.link}
                trackingData={{ ...trackingData, eventLabel: `${item.label}` }}
              >
                <Crumb>{item.label}</Crumb>
              </Link>
            )}
          </CrumbWrapper>
        ))}
      </List>
    </Wrapper>
  );
};

export default withErrorBoundary(Breadcrumbs, { fragment: UIFragments.NAVIGATION });
